import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Divider, Form, FormInstance, Image, Spin, Upload, UploadProps } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { assetsApi, storageProcessApi } from '../../../../apis';
import { StorageItem, TakePhotoRequest, UploadPhotoAndVideoDto } from '../../../../apis/client-axios';
import FormWrap from '../../../../components/FormWrap';
import NotificationSuccess from '../../../../components/notifications/NotificationSuccess';
import { QUERY_KEY, STATUS_ITEM_MANAGEMENT, TAKE_PHOTO_REQUEST_STATUS } from '../../../../util/constants';
import { Helper } from '../../../../util/helper';
import {
  CloseOutlined,
  RotateLeftOutlined,
  RotateRightOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from '@ant-design/icons';
import NotificationError from '../../../../components/notifications/NotificationError';
import _ from 'lodash';

const { Dragger } = Upload;

export interface IUploadFiles {
  statusUploadFiles: { isShow: boolean; takePhotoRequest: TakePhotoRequest };
  setStatusUploadFiles: React.Dispatch<React.SetStateAction<{ isShow: boolean; takePhotoRequest: TakePhotoRequest }>>;
  status: string;
  formUploadByStatus: FormInstance;
  itemDetail: StorageItem;
  visibleSender: boolean;
  previewSender: { source: string; index: number } | undefined;
  setPreviewSender: (previewSender: { source: string; index: number } | undefined) => void;
  renderListPhoto: React.ReactNode;
  renderListVideo: React.ReactNode;
  renderTakePhotoRequest: React.ReactNode;
  setIsLoadingUploadMedia: React.Dispatch<React.SetStateAction<boolean>>;
  isLoadingUploadMedia: boolean;
}

const UploadMedia = (props: IUploadFiles) => {
  const {
    statusUploadFiles,
    setStatusUploadFiles,
    visibleSender,
    previewSender,
    setPreviewSender,
    status,
    formUploadByStatus,
    itemDetail,
    renderListPhoto,
    renderListVideo,
    renderTakePhotoRequest,
    setIsLoadingUploadMedia,
    isLoadingUploadMedia,
  } = props;
  const intl = useIntl();
  const queryClient = useQueryClient();
  const [isUpload, setIsUpload] = useState(true);
  const [isUploadVideo, setIsUploadVideo] = useState(true);
  const [fileList, setFileList] = useState<any>();
  const [fileListVideo, setFileListVideo] = useState<any>();
  const [fileUploads, setFileUploads] = useState<
    { id: number | null; preview: string; source: File | null }[] | undefined
  >(undefined);
  const [fileUploadVideos, setFileUploadVideos] = useState<
    { id: number | null; preview: string; source: File | null }[] | undefined
  >(undefined);
  const [isLoadingUploadImg, setLoadingUploadImg] = useState(false);
  const UploadFile = useMutation({
    mutationFn: (dto: File) => assetsApi.assetControllerUploadFile(dto, 'item-photo').then((res) => res?.data),
    onSuccess: () => {},
    onError: (error) => {
      setIsLoadingUploadMedia(false);
    },
  });

  const UpdateStorageProcessMutation = useMutation({
    mutationFn: (dto: UploadPhotoAndVideoDto) => storageProcessApi.storageProcessControllerUpdatePhotoItem(dto),
    onSuccess: () => {
      setStatusUploadFiles({
        ...statusUploadFiles,
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.ITEM_DETAIL],
        stale: true,
      });
      setFileUploads(undefined);
      setFileUploadVideos(undefined);
      NotificationSuccess({ content: intl.formatMessage({ id: 'common.updateSuccess' }) });
      setIsLoadingUploadMedia(false);
    },
    onError: (error) => {
      setIsLoadingUploadMedia(false);
    },
  });

  const handleChangeUploadFile: UploadProps['onChange'] = async ({ fileList: newFileList }) => {
    if (!!newFileList?.length) {
      try {
        if (isUpload) {
          setLoadingUploadImg(true);
          setTimeout(() => {
            const listUrl = newFileList?.map((file: any) => {
              return {
                id: null,
                preview: (window.URL ? URL : webkitURL).createObjectURL(file?.originFileObj),
                source: file?.originFileObj,
              };
            });
            const uploads = fileUploads ? [...fileUploads, ...listUrl] : listUrl;
            setFileUploads(uploads);
            setFileList(newFileList);
            setLoadingUploadImg(false);
            setIsUpload(false);
          }, 2000);
        }
      } catch (error) {
        console.log(error);
        setLoadingUploadImg(false);
      }
    }
  };

  const handleChangeUploadVideoFile: UploadProps['onChange'] = async ({ fileList: newFileList }) => {
    if (!!newFileList?.length) {
      try {
        if (isUploadVideo) {
          const listUrl = newFileList?.map((file: any) => {
            return {
              id: null,
              preview: (window.URL ? URL : webkitURL).createObjectURL(file?.originFileObj),
              source: file?.originFileObj,
            };
          });
          const uploads = fileUploadVideos ? [...fileUploadVideos, ...listUrl] : listUrl;
          setFileUploadVideos(uploads);
          setFileListVideo(newFileList);
          setIsUploadVideo(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleRemoveFile = (file: { preview: string; source: any }) => {
    setFileList(fileList?.filter((item) => item?.uid != file?.source?.uid));
    setFileUploads(fileUploads?.filter((item: any) => item !== file));
  };
  const handleRemoveVideoFile = (file: { preview: string; source: any }) => {
    setFileListVideo(fileListVideo?.filter((item) => item?.uid != file?.source?.uid));
    setFileUploadVideos(fileUploadVideos?.filter((item: any) => item !== file));
  };

  const onFinish = async (value) => {
    try {
      setIsLoadingUploadMedia(true);
      if (itemDetail?.storageRequest?.unboxVideoFee > 0 && !itemDetail?.videos?.length && !fileListVideo?.length) {
        NotificationError({
          content: intl.formatMessage({ id: 'item.detail.validateVideoUnbox' }),
        });
        return;
      }
      let assetImgIds = [];
      let assetVideoIds = [];
      if (!!fileUploads?.length) {
        const promiseUpload = fileUploads?.map((item) => {
          if (item?.id) {
            return item?.id;
          }
          return UploadFile.mutateAsync(item?.source);
        });
        assetImgIds = await Promise.all([...promiseUpload]).then();
      }
      if (!!fileUploadVideos?.length) {
        setIsLoadingUploadMedia(true);

        if (!!fileUploadVideos?.length) {
          const promiseUploadVideo = fileUploadVideos?.map((item) => {
            if (item?.id) {
              return item?.id;
            }
            return UploadFile.mutateAsync(item?.source);
          });
          assetVideoIds = await Promise.all([...promiseUploadVideo]);
        }
      }

      const _photoIds = [...itemDetail?.photos, ...assetImgIds]?.map((item) => item.id);
      const _videoIds = [...itemDetail?.videos, ...assetVideoIds]?.map((item) => item.id);
      UpdateStorageProcessMutation.mutateAsync({
        storageItemId: itemDetail.id,
        photoIds: !!_photoIds.length ? _photoIds : [],
        videoIds: !!_videoIds.length ? _videoIds : [],
      });
    } catch (e) {
      console.log(e);
      setIsLoadingUploadMedia(false);
    }
  };

  const isShowPhotoRequest = useMemo(() => {
    if (
      [
        STATUS_ITEM_MANAGEMENT.IN_TRANSIT,
        STATUS_ITEM_MANAGEMENT.DELIVERED,
        STATUS_ITEM_MANAGEMENT.CANCELED,
        STATUS_ITEM_MANAGEMENT.NOT_SHIPPED,
        STATUS_ITEM_MANAGEMENT.WAITING_STORAGE,
        STATUS_ITEM_MANAGEMENT.WAITING_UPLOAD_IMAGE_VIDEO,
      ].indexOf(status) >= 0
    ) {
      return false;
    } else {
      return true;
    }
  }, [status]);

  return (
    <Spin size="large" spinning={isLoadingUploadMedia} className="spin-media">
      <FormWrap form={formUploadByStatus} onFinish={onFinish} className="form-upload">
        <div className="item-detail_content_photo">
          <button className="item-detail_content_top_button-wrap_shipping-request">
            {intl.formatMessage({ id: 'item.detail.registration' })}
          </button>
          {/* {status === STATUS_ITEM_MANAGEMENT.WAITING_UPLOAD_IMAGE_VIDEO ? (
          ) : (
            <></>
          )} */}
          <div className="item-detail_add-photo_body_wrap">
            <h6 className="item-detail_add-photo_body_wrap_title">
              <p>{intl.formatMessage({ id: 'item.upload.title' })}</p>
              <Upload
                className="cursor-pointer"
                customRequest={() => {}}
                multiple={true}
                showUploadList={false}
                onChange={handleChangeUploadFile}
                fileList={fileList}
                accept="image/*"
                beforeUpload={() => {
                  setIsUpload(true);
                  setFileList([]);
                }}
                // disabled={status !== STATUS_ITEM_MANAGEMENT.WAITING_UPLOAD_IMAGE_VIDEO}
              >
                <button
                  type="button"
                  // disabled={status !== STATUS_ITEM_MANAGEMENT.WAITING_UPLOAD_IMAGE_VIDEO}
                  className={`${statusUploadFiles?.takePhotoRequest?.status?.toLocaleLowerCase()}`}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d="M18 14V16.6667C18 17.0203 17.8595 17.3594 17.6095 17.6095C17.3594 17.8595 17.0203 18 16.6667 18H7.33333C6.97971 18 6.64057 17.8595 6.39052 17.6095C6.14048 17.3594 6 17.0203 6 16.6667V14M15.3333 9.33333L12 6M12 6L8.66667 9.33333M12 6V14"
                      stroke="#1C86ED"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <p>{intl.formatMessage({ id: 'item.upload.btn' })}</p>
                </button>
              </Upload>
            </h6>
            {/*  {statusUploadFiles?.takePhotoRequest?.status !== TAKE_PHOTO_REQUEST_STATUS.REQUESTED && (
            )} */}
            <Spin spinning={isLoadingUploadImg}>
              <Form.Item name="file-upload" className="m-b-0 m-t-16">
                <Dragger
                  name="file"
                  multiple={true}
                  customRequest={() => {}}
                  showUploadList={false}
                  onChange={handleChangeUploadFile}
                  beforeUpload={() => {
                    setIsUpload(true);
                    setFileList([]);
                  }}
                  fileList={fileList}
                  accept="image/*"
                  className="up-image custom-dragger"
                >
                  <div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M8.33333 6.25C7.18274 6.25 6.25 7.18274 6.25 8.33333V31.6667C6.25 32.8173 7.18274 33.75 8.33333 33.75H31.6667C32.8173 33.75 33.75 32.8173 33.75 31.6667V8.33333C33.75 7.18274 32.8173 6.25 31.6667 6.25H8.33333ZM3.75 8.33333C3.75 5.80203 5.80203 3.75 8.33333 3.75H31.6667C34.198 3.75 36.25 5.80203 36.25 8.33333V31.6667C36.25 34.198 34.198 36.25 31.6667 36.25H8.33333C5.80203 36.25 3.75 34.198 3.75 31.6667V8.33333Z"
                        fill="#514B4D"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M14.1665 12.916C13.4761 12.916 12.9165 13.4757 12.9165 14.166C12.9165 14.8564 13.4761 15.416 14.1665 15.416C14.8569 15.416 15.4165 14.8564 15.4165 14.166C15.4165 13.4757 14.8569 12.916 14.1665 12.916ZM10.4165 14.166C10.4165 12.0949 12.0954 10.416 14.1665 10.416C16.2376 10.416 17.9165 12.0949 17.9165 14.166C17.9165 16.2371 16.2376 17.916 14.1665 17.916C12.0954 17.916 10.4165 16.2371 10.4165 14.166Z"
                        fill="#514B4D"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M25.7829 15.7821C26.2711 15.294 27.0626 15.294 27.5507 15.7821L35.884 24.1155C36.3722 24.6036 36.3722 25.3951 35.884 25.8832C35.3959 26.3714 34.6044 26.3714 34.1163 25.8832L26.6668 18.4338L9.21738 35.8832C8.72922 36.3714 7.93777 36.3714 7.44961 35.8832C6.96146 35.3951 6.96146 34.6036 7.44961 34.1155L25.7829 15.7821Z"
                        fill="#514B4D"
                      />
                    </svg>
                    <p>{intl.formatMessage({ id: 'item.upload.drag' })}</p>
                  </div>
                </Dragger>
              </Form.Item>
              <div className="item-detail_add-photo_body_list">
                {!!fileUploads?.length &&
                  fileUploads
                    .sort((a, b) => a?.source?.name?.localeCompare(b?.source?.name))
                    .map((file) => {
                      return (
                        <div className="item-detail_add-photo_body_list_item">
                          <img src={file.preview} alt="" />
                          {statusUploadFiles?.takePhotoRequest?.status !==
                            TAKE_PHOTO_REQUEST_STATUS.UPLOAD_BY_STAFF && (
                            <button onClick={() => handleRemoveFile(file)} type="button">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="11"
                                viewBox="0 0 10 11"
                                fill="none"
                              >
                                <path
                                  d="M9 1.5L1 9.5M1 1.5L9 9.5"
                                  stroke="#514B4D"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </button>
                          )}
                        </div>
                      );
                    })}
              </div>
            </Spin>
          </div>

          <div className="item-detail_content_photo_list">
            <Image.PreviewGroup
              preview={
                visibleSender
                  ? {
                      rootClassName: `image-preview__container  `,
                      onChange: (current, prev) => {
                        const arr = _.cloneDeep(itemDetail?.photos);
                        if (itemDetail?.takePhotoRequests?.length > 0) {
                          itemDetail?.takePhotoRequests.map((rq) => {
                            arr.push(...rq.photos);
                          });
                        }
                        setPreviewSender({
                          source: arr[current]?.source,
                          index: current,
                        });
                      },
                      src: Helper.getSourceFile(previewSender?.source),
                      toolbarRender: (
                        _,
                        {
                          transform: { scale },
                          actions: { onRotateLeft, onRotateRight, onZoomOut, onZoomIn, onClose },
                        },
                      ) => (
                        <div className="ant-image-preview-operations">
                          <div className="ant-image-preview-operations-operation ant-image-preview-operations-operation-zoomOut">
                            <RotateLeftOutlined onClick={onRotateLeft} />
                          </div>
                          <div className="ant-image-preview-operations-operation ant-image-preview-operations-operation-zoomOut">
                            <RotateRightOutlined onClick={onRotateRight} />
                          </div>
                          <div
                            className={`ant-image-preview-operations-operation ant-image-preview-operations-operation-zoomOut ${scale === 1 && 'ant-image-preview-operations-operation-disabled'}`}
                          >
                            <ZoomOutOutlined disabled={true} onClick={onZoomOut} />
                          </div>
                          <div className="ant-image-preview-operations-operation ant-image-preview-operations-operation-zoomOut">
                            <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                          </div>
                          <div className="ant-image-preview-operations-operation ant-image-preview-operations-operation-zoomOut">
                            <Divider type="vertical" className="bg-white" />
                          </div>
                          <div className="ant-image-preview-operations-operation ant-image-preview-operations-operation-zoomOut">
                            <CloseOutlined
                              disabled={scale === 50}
                              onClick={() => {
                                onClose();
                              }}
                            />
                          </div>
                        </div>
                      ),
                    }
                  : false
              }
            >
              {renderListPhoto}
            </Image.PreviewGroup>
            {/* TODO: trường hợp upload */}
            {/* <div className="item-detail_content_photo_list_item">
                <img src="/assets/icons/item-detail-demo.png" alt="" />
                <button className='item-detail_content_photo_list_item_remove'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                    <path
                      d="M9 1L1 9M1 1L9 9"
                      stroke="#514B4D"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              </div> */}
          </div>
          {isShowPhotoRequest && (
            <div className="item-detail_content_photo_table">
              <h6 className="item-detail_content_photo_table_title">
                {intl.formatMessage({ id: 'item.detail.addAPhoto' })}
              </h6>
              <div className="item-detail_content_photo_table_wrap ebay__scroll-bar-custom">
                <table>
                  <tr>
                    <th>
                      <p>{intl.formatMessage({ id: 'item.detail.requestDateAndTime' })}</p>
                    </th>
                    <th>
                      <p>{intl.formatMessage({ id: 'item.detail.numberOfShots' })}</p>
                    </th>
                    <th>
                      <p>{intl.formatMessage({ id: 'item.detail.comment' })}</p>
                    </th>
                    <th>
                      <p>{intl.formatMessage({ id: 'item.detail.situation' })}</p>
                    </th>
                  </tr>
                  {renderTakePhotoRequest}
                </table>
              </div>
            </div>
          )}
        </div>
        <div className="item-detail_content_video">
          <div className="item-detail_add-photo_body_wrap">
            <h6 className="item-detail_add-photo_body_wrap_title">
              <div className="d-flex align-items-center">
                <p>{intl.formatMessage({ id: 'item.detail.movie' })}</p>
                {itemDetail?.storageRequest?.unboxVideoFee > 0 &&
                  status == STATUS_ITEM_MANAGEMENT.WAITING_UPLOAD_IMAGE_VIDEO && (
                    <span className="item-detail__record-require">
                      {intl.formatMessage({ id: 'item.detail.movieRequire' })}
                    </span>
                  )}
              </div>
              <Upload
                className="cursor-pointer"
                action={undefined}
                customRequest={() => {}}
                multiple={true}
                showUploadList={false}
                onChange={handleChangeUploadVideoFile}
                fileList={fileListVideo}
                accept="video/mp4,video/x-m4v,video/*"
                // disabled={status !== STATUS_ITEM_MANAGEMENT.WAITING_UPLOAD_IMAGE_VIDEO}
                beforeUpload={() => {
                  setIsUploadVideo(true);
                  setFileListVideo([]);
                }}
              >
                <button
                  type="button"
                  // disabled={
                  //   !(
                  //     status === STATUS_ITEM_MANAGEMENT.WAITING_UPLOAD_IMAGE_VIDEO &&
                  //     itemDetail?.storageRequest?.unboxVideoFee > 0
                  //   )
                  // }
                  // className={`${statusUploadFiles?.takePhotoRequest?.status?.toLocaleLowerCase()}`}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d="M18 14V16.6667C18 17.0203 17.8595 17.3594 17.6095 17.6095C17.3594 17.8595 17.0203 18 16.6667 18H7.33333C6.97971 18 6.64057 17.8595 6.39052 17.6095C6.14048 17.3594 6 17.0203 6 16.6667V14M15.3333 9.33333L12 6M12 6L8.66667 9.33333M12 6V14"
                      stroke="#1C86ED"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <p>{intl.formatMessage({ id: 'item.upload.upload' })}</p>
                </button>
              </Upload>
            </h6>
            <Form.Item name="file-upload" className="m-b-0 m-t-16">
              <Dragger
                name="file"
                customRequest={() => {}}
                multiple={true}
                showUploadList={false}
                onChange={handleChangeUploadVideoFile}
                beforeUpload={() => {
                  setIsUploadVideo(true);
                  setFileListVideo([]);
                }}
                fileList={fileListVideo}
                accept="video/mp4,video/x-m4v,video/*"
                className="up-video"
              >
                <div>
                  <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M0 8.41667C0 6.80667 1.30667 5.5 2.91667 5.5H37.0833C38.6933 5.5 40 6.80667 40 8.41667V32.5833C40 33.3569 39.6927 34.0987 39.1457 34.6457C38.5987 35.1927 37.8569 35.5 37.0833 35.5H2.91667C2.14312 35.5 1.40125 35.1927 0.854272 34.6457C0.307291 34.0987 0 33.3569 0 32.5833L0 8.41667ZM2.91667 8C2.80616 8 2.70018 8.0439 2.62204 8.12204C2.5439 8.20018 2.5 8.30616 2.5 8.41667V32.5833C2.5 32.8133 2.68667 33 2.91667 33H37.0833C37.1938 33 37.2998 32.9561 37.378 32.878C37.4561 32.7998 37.5 32.6938 37.5 32.5833V8.41667C37.5 8.30616 37.4561 8.20018 37.378 8.12204C37.2998 8.0439 37.1938 8 37.0833 8H2.91667Z"
                      fill="#514B4D"
                    />
                    <path
                      d="M15 26.4739V14.5273C15.0002 14.3778 15.0407 14.2311 15.1171 14.1027C15.1935 13.9742 15.3031 13.8686 15.4343 13.7971C15.5655 13.7255 15.7136 13.6905 15.8629 13.6958C16.0123 13.7012 16.1575 13.7465 16.2833 13.8273L25.5767 19.7989C25.6943 19.8743 25.7912 19.9781 25.8582 20.1007C25.9253 20.2233 25.9604 20.3608 25.9604 20.5006C25.9604 20.6403 25.9253 20.7779 25.8582 20.9005C25.7912 21.0231 25.6943 21.1269 25.5767 21.2023L16.2833 27.1756C16.1575 27.2563 16.0123 27.3017 15.8629 27.307C15.7136 27.3123 15.5655 27.2774 15.4343 27.2058C15.3031 27.1342 15.1935 27.0287 15.1171 26.9002C15.0407 26.7717 15.0002 26.6251 15 26.4756V26.4739Z"
                      fill="#514B4D"
                    />
                  </svg>

                  <p>{intl.formatMessage({ id: 'item.upload.upload.drag' })}</p>
                </div>
              </Dragger>
            </Form.Item>
            <div className="item-detail_add-photo_body_list justify-content-start">
              {!!fileUploadVideos?.length &&
                fileUploadVideos
                  ?.sort((a, b) => a?.source?.name?.localeCompare(b?.source?.name))
                  ?.map((file) => {
                    return (
                      <div className="item-detail__video-container">
                        <video width="250" controls>
                          <source src={file.preview} type={file.source.type} />
                        </video>
                        {statusUploadFiles?.takePhotoRequest?.status !== TAKE_PHOTO_REQUEST_STATUS.UPLOAD_BY_STAFF && (
                          <button onClick={() => handleRemoveVideoFile(file)}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="10"
                              height="11"
                              viewBox="0 0 10 11"
                              fill="none"
                            >
                              <path
                                d="M9 1.5L1 9.5M1 1.5L9 9.5"
                                stroke="#514B4D"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </button>
                        )}
                      </div>
                    );
                  })}
            </div>
          </div>
          <div className="item-detail_content_video_list">{renderListVideo}</div>
        </div>
      </FormWrap>
    </Spin>
  );
};

export default UploadMedia;
